import { TagsSelectorService } from 'app/tags-selector/tags-selector.service';

const IMAGE_CLASS = 'sf_imageFallback_image';

export const ImageFallbackComponent = {
  bindings: {
    sfImageUrl: '<',
    errorImageUrl: '<',
    isCover: '<',
    commentCount: '<',
    onImageLoadedEmmit: '<',
    tagsIds: '<',
  },
  templateUrl: 'components/Images/image-fallback/image-fallback.html',
  controller: class ImageFallbackController implements ng.IComponentController {
    // bindings
    sfImageUrl: string;
    errorImageUrl: string;
    isCover: boolean;
    commentCount = 0;
    onImageLoadedEmmit: () => void;

    // html elements
    $picture: Element | null;

    isLoading: boolean;
    isError: boolean;
    isBlob: boolean;
    imageUrl: string;
    coverStyle: { backgroundImage: string };
    tagsIds: string[];
    tagLabel: string;

    constructor(
      private $attrs: ng.IAttributes,
      private $element: ng.IRootElementService,
      private $scope: ng.IRootScopeService,
      private tagsSelectorService: TagsSelectorService,
      private TAG_LABEL_LENGTH
    ) {
      'ngInject';
    }

    $onInit(): void {
      this.isBlob = this.sfImageUrl?.indexOf('blob') !== -1;
      this.imageUrl = this.isBlob ? this.sfImageUrl : this.getImgUrl();
      this.isLoading = true;
      this.isError = false;

      if (!this.imageUrl) {
        return;
      }

      // This source don't need to have loading or error state.
      if (this.isBlob) {
        this.onImageLoadedForBlob(); // we still need to set .display class with opacity:1 to blobs
      }

      if (this.isCover) {
        this.loadBackgroundImage();
      }
    }

    $postLink(): void {
      this.$picture = this.getElemByClass(IMAGE_CLASS);

      ['alt', 'width', 'height'].forEach((name) => {
        if (undefined !== this.$attrs[name] && this.$attrs[name] !== null) {
          this.$picture?.setAttribute(name, this.$attrs[name]);
        }
      });
    }

    $onChanges(changesObj: ng.IOnChangesObject): void {
      if (changesObj.sfImageUrl) {
        this.$onInit();
      }

      if (changesObj.tagsIds) {
        this.getTaglabel();
      }
    }

    getTaglabel(): void {
      if (!this.tagsIds?.length) {
        return;
      }
      let maxLength = this.TAG_LABEL_LENGTH.ONE_TAG;

      const trimAndAddEllipsis = (inputString, maxLength) => {
        if (inputString.length >= maxLength) {
          return inputString.slice(0, maxLength - 3) + '...';
        } else {
          return inputString;
        }
      };

      this.tagsSelectorService.getTagsByIds(this.tagsIds).then((tags) => {
        if (tags.length >= 2) {
          maxLength = this.TAG_LABEL_LENGTH.TWO_OR_MORE;
        }
        this.tagLabel = `${trimAndAddEllipsis(
          tags[0]?.contents.name,
          maxLength
        )} ${tags.length >= 2 ? `(+${tags.length - 1})` : ''}`;
      });
    }

    loadBackgroundImage(): void {
      const img = new Image();

      img.onerror = this.onImageError.bind(this);
      img.onload = this.onImageLoaded.bind(this);
      img.src = this.imageUrl;
    }

    reloadImg(): void {
      this.imageUrl = this.getImgUrl();
      this.isLoading = true;
      this.isError = false;
    }

    private computeCoverStyle(withApply = true): void {
      const action = () => {
        this.coverStyle = {
          backgroundImage: this.isError
            ? `url('${this.errorImageUrl}'), url('img/icons/contextual_error.svg')`
            : `url('${this.imageUrl}')`,
        };
      };

      if (withApply) {
        // Since it's loaded in background, we need to notify digest cycle
        this.$scope.$apply(() => action());
      } else {
        action();
      }
    }

    private onImageLoaded(): void {
      this.isLoading = false;
      this.isError = false;

      if (this.isCover) {
        this.computeCoverStyle();
      }

      if (this.onImageLoadedEmmit) {
        this.onImageLoadedEmmit();
      }
    }

    private onImageLoadedForBlob(): void {
      this.isLoading = false;
      this.isError = false;

      if (this.isCover) {
        this.computeCoverStyle(false);
      }

      if (this.onImageLoadedEmmit) {
        this.onImageLoadedEmmit();
      }
    }

    private onImageError(): void {
      this.isLoading = false;
      this.isError = true;
      if (this.isCover) {
        this.computeCoverStyle();
      }
    }

    private getImgUrl(): string {
      const cache = new Date().getTime();
      const urlChar =
        this.sfImageUrl && this.sfImageUrl.indexOf('?') === -1 ? '?' : '&';

      return `${this.sfImageUrl}${urlChar}${cache}`;
    }

    private getElemByClass(className: string) {
      return this.$element[0].querySelector(`.${className}`);
    }
  },
};
