import { clone, groupBy, prop, take } from 'ramda';
import { CommentsResourceType } from '../../comments/services/comments-api/comments-api.factory';

const WAITING_SAVE_TIME = 2000;
const NB_STATUS_CAMPAIGN_MIN = 1;
const NB_STATUS_MIN = 2;
const FORM_CURRENT_SECTION_STORAGE_KEY = 'form_current_section';
const INVALID_ANSWER_BANNER_CLOSE_TIMEOUT = 10;
// Values to substract to offset so that the scroll lines up correctly with the header and nav bar
const SECTION_NAVIGATION_BAR_HEIGHT = 60;
const HEADER_BAR_HEIGHT = 40;

export const FormComponent = {
  bindings: {
    report: '<',
    campaign: '<',
    form: '<',
    place: '<',
    user: '<',
    referer: '<',
    isPreview: '<',
    template: '<?',
  },
  templateUrl: 'missions/form/form.html',
  controller: class FormController {
    // eslint-disable-next-line max-params
    constructor(
      localizationService,
      $filter,
      $q,
      $stateParams,
      $element,
      routerService,
      pubSubService,
      contentService,
      $timeout,
      modalService,
      $urlRouter,
      $translate,
      localStorageService,
      popupService,
      keyboardService,
      formService,
      ReportErrorsService,
      formSheetService,
      formsService,
      LIFECYCLE_EVENTS,
      $document,
      tasksService,
      platformService,
      KEYBOARD_MODES,
      campaignsUtilsService,
      campaignsService,
      formTasksService,
      debugService,
      templatesService,
      commentsFactory,
      placesParamsService,
      $rootScope,
      sfFeatureFlagsService,
      SF_FEATURE_FLAGS,
      reportsService,
      draftReportsService,
      pingService,
      SF_ERROR_CODES,
      popupRequestService,
      awakeFactory,
      errorMessagesService
    ) {
      'ngInject';
      this.localizationService = localizationService;

      this.commentsService = commentsFactory(CommentsResourceType.ANSWERS);
      this.commentsService.deleteAllLocalComments();

      this.FORM_ENDING_POPUP_SAVE_CONFIG = {
        progress: {
          title: $translate.instant('FORM_POPUP_SEND_SENDING_TITLE'),
          desc: $translate.instant('FORM_POPUP_SEND_SENDING_DESC_CHECKLISTS'),
        },
        success: {
          title: $translate.instant(
            'FORM_POPUP_SEND_DRAFT_SUCCEED_TITLE_CHECKLISTS'
          ),
          desc: $translate.instant(
            'FORM_POPUP_SEND_DRAFT_SUCCEED_DESC_CHECKLISTS'
          ),
          iconName: 'sync_reports_success',
        },
        error: {
          title: $translate.instant('FORM_POPUP_SEND_FORM_ERROR_TITLE'),
          desc: $translate.instant(
            'FORM_POPUP_SEND_FORM_ERROR_DESC_CHECKLISTS'
          ),
          btnText: $translate.instant('FORM_POPUP_SEND_FORM_ERROR_BUTTON'),
        },
        internetConnectionIssue: {
          title: $translate.instant('FORM_POPUP_SEND_FORM_ERROR_TITLE'),
          desc: $translate.instant(
            'FORM_POPUP_SEND_FORM_ERROR_DESC_CHECKLISTS'
          ),
          btnText: $translate.instant('FORM_POPUP_SEND_FORM_ERROR_BUTTON'),
          iconName: 'send-later',
        },
      };

      Object.assign(this, {
        $filter,
        $q,
        $stateParams,
        $element,
        pubSubService,
        contentService,
        $timeout,
        modalService,
        routerService,
        $urlRouter,
        $translate,
        localStorageService,
        popupService,
        keyboardService,
        formService,
        ReportErrorsService,
        formSheetService,
        formsService,
        LIFECYCLE_EVENTS,
        $document,
        tasksService,
        platformService,
        KEYBOARD_MODES,
        campaignsUtilsService,
        campaignsService,
        formTasksService,
        debugService,
        templatesService,
        placesParamsService,
        $rootScope,
        sfFeatureFlagsService,
        SF_FEATURE_FLAGS,
        reportsService,
        draftReportsService,
        pingService,
        SF_ERROR_CODES,
        popupRequestService,
        awakeFactory,
        errorMessagesService,
      });
    }

    $onInit() {
      if (
        this.campaign &&
        this.campaign.contents.posAuditParam_id &&
        !this.report.contents.posAudit
      ) {
        this.$rootScope.$broadcast('closeCampaignParamSelector'); //To prevent modal overload
        this.openModalPOSAuditParam();
      }

      this.editDraft = this.report.editDraft;
      this.events = [];

      this.isRTLNeeded = this.localizationService.shouldActivateRTL();
      if (this.campaign) {
        this.useNewCampaigns = true;
        this.form = this.campaignsUtilsService.getFormFromCampaign(
          this.campaign
        );
      }
      if (this.template) {
        this.useNewCampaigns = true;
        this.form = this.templatesService.getFormFromTemplate(this.template);
      }

      const { questionId, isDebug, shouldRedirectToFinalize } =
        this.$stateParams;

      const currentSessionStored = this.$stateParams.viewRestored
        ? this.localStorageService.get(FORM_CURRENT_SECTION_STORAGE_KEY)
        : null;

      // Saved dispersion.
      this.timerSaved = null;
      this.waitingSaved = false;
      // Scroll
      this.changeEvent = null;
      this.lifecyclePauseEvent = null;
      this.lifecycleResumeEvent = null;
      this.keyboardShowEvent = null;

      this.formScroll = this.contentService.getScroll('formScroll');
      this.formPristine = this.isFormDirty();

      this.form = this.formsService.removeLegacy(this.form);
      this.questionForm = {};
      this.isReportFinish = !this.isReportDraft();
      this.isAndroid = this.platformService.isAndroid();
      this.unconfirmedPrefilledQuestions = { ids: [] };
      this.availablePreviousAnswers = [];
      this.stateErrorConfig =
        this.campaign || this.template
          ? {
              title: this.$translate.instant(
                'CAMPAIGN_LAST_ANSWER_ERROR_TITLE'
              ),
              desc: this.$translate.instant('CAMPAIGN_LAST_ANSWER_ERROR_DESC'),
              reloadButtonText: this.$translate.instant(
                'CAMPAIGN_LAST_ANSWER_ERROR_RELOAD'
              ),
              cancelButtonText: this.$translate.instant(
                'CAMPAIGN_LAST_ANSWER_ERROR_CANCEL'
              ),
            }
          : {
              title: this.$translate.instant('FORM_LAST_ANSWER_ERROR_TITLE'),
              desc: this.$translate.instant('FORM_LAST_ANSWER_ERROR_DESC'),
              reloadButtonText: this.$translate.instant(
                'FORM_LAST_ANSWER_ERROR_RELOAD'
              ),
              cancelButtonText: this.$translate.instant(
                'FORM_LAST_ANSWER_ERROR_CANCEL'
              ),
            };
      this.isDebug = isDebug;

      this.invalidAnswersNb = 0;
      this.invalidAnswersIndex = 0;

      const promise = this.hasPrefillAnswers(this.form)
        ? this.loadLastAnswers()
        : this.$q.when();

      this.hasBackendDraftsFlag = this.sfFeatureFlagsService.hasFeature(
        this.SF_FEATURE_FLAGS.DRAFT_REPORTS
      );

      return promise
        .then(() => this.initTasks())
        .then(() => {
          this.formErrors = new this.ReportErrorsService({
            form: this.form,
            place: this.place,
            user: this.user,
            report: this.report,
            questionForm: this.questionForm,
            unconfirmedPrefilledQuestions: this.unconfirmedPrefilledQuestions,
          });

          this.formTree = this.formsService.getFormTree(this.form);
          // filter sections which shouldn't be displayed according custom conditions
          this.formSections = this.form.contents.sections
            .filter((section) => section.level === 0)
            .map((formSection) => ({
              ...formSection,
              description: this.$filter('sfTranslation')(
                formSection.description,
                this.form.i18n
              ),
            }));

          this.currentIndex = 0;
          this.currentSection = this.formTree[0];

          this.nbErrors = this.getNbErrors();

          this.answerChangedListener = this.pubSubService.subscribe(
            'QUESTION_ANSWER_CHANGED',
            () => {
              this.nbErrors = this.getNbErrors();
            }
          );

          this.registerOnExit();
          this.registerLifecycleEvent();

          if (!this.isAndroid) {
            this.registerKeyboardEvent();
          }

          this.removeSectionStorage();

          if (questionId) {
            return this.focusQuestion(questionId);
          }

          if (shouldRedirectToFinalize) {
            this.goBack();
            return this.finalizeForm();
          }

          return this.setNavState(
            currentSessionStored ? currentSessionStored.index || 0 : 0
          );
        });
    }

    $onDestroy() {
      this.cleanFormView();
      this.commentsService.deleteAllLocalComments();
    }

    killEvent(fn) {
      fn && fn();
    }

    goBack() {
      if (this.isPreview) {
        return;
      }

      return this.routerService.goBack(
        this.isPreview
          ? 'preview.mission.detail'
          : 'index.menu-more.missions.campaign.details'
      );
    }

    loadLastAnswers() {
      this.isLoading = true;
      this.networkError = false;
      const params = this.place ? { place_id: this.place._id } : {};

      const promise = this.campaign
        ? this.campaignsService.getLastReportAnswers(this.campaign._id, params)
        : this.formsService.getLastReportAnswers(this.form._id, params);

      return promise
        .then((lastAnswers) => {
          const filteredLastAnswers = this.filterLastAnswers(
            lastAnswers,
            this.form.contents.questions
          );
          const { nodes, previousAnswers } =
            this.buildNodesForPreviousAnswers(filteredLastAnswers);

          this.report.contents.nodes = nodes;
          this.availablePreviousAnswers = previousAnswers;

          const unfilledPreviousAnswers = this.availablePreviousAnswers.filter(
            (previousAnswer) =>
              !this.report.contents.answers.some(
                (reportAnswer) =>
                  reportAnswer.question_id === previousAnswer.question_id
              )
          );

          this.unconfirmedPrefilledQuestions.ids = unfilledPreviousAnswers.map(
            (answer) => answer.question_id
          );
          this.report.contents.answers = this.report.contents.answers.concat(
            unfilledPreviousAnswers
          );
        })
        .catch(() => {
          this.networkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }

    /**
     * Filter last answers from previous report
     * Only keep the allowed number of answers, regarding their question's maximum, if any
     * For now only check when maximum is explictly an integer
     */
    filterLastAnswers(lastAnswers, questions) {
      const answers = lastAnswers.answers || [];

      const groupByQuestionId = groupBy(prop('question_id'));
      const answersSubLists = groupByQuestionId(answers);

      const filteredSubLists = Object.entries(answersSubLists).map(
        ([questionId, subList]) => {
          if (!Array.isArray(subList) || subList.length < 1) {
            return [];
          }

          const question = questions.find(
            (question) => question._id === questionId
          );

          if (!question) {
            return [];
          }

          if (Number.isInteger(question.maximum)) {
            return take(question.maximum, subList);
          }

          return subList;
        }
      );

      const filteredAnswers = filteredSubLists.flat();

      return {
        ...lastAnswers,
        answers: filteredAnswers,
      };
    }

    resetState() {
      this.isLoading = false;
      this.networkError = false;
    }

    registerOnExit() {
      if (this.isPreview) {
        return false;
      }
      this.changeEvent = this.pubSubService.subscribe(
        this.pubSubService.GLOBAL_EVENTS.STATE_CHANGE_START,
        (_, e) => this.openActionSheetOnExit(e)
      );
      return true;
    }

    registerLifecycleEvent() {
      this.lifecyclePauseEvent = this.pubSubService.subscribe(
        this.LIFECYCLE_EVENTS.PAUSE,
        () => {
          this.localStorageService.set(FORM_CURRENT_SECTION_STORAGE_KEY, {
            index: this.currentIndex,
          });
          this.saveLocally({
            cleanReport: false,
            removeUnconfirmedPrefilled: true,
          });
        }
      );
      this.lifecycleResumeEvent = this.pubSubService.subscribe(
        this.LIFECYCLE_EVENTS.RESUME,
        () => {
          const unfilledPreviousAnswers = this.availablePreviousAnswers.filter(
            (answer) =>
              !this.report.contents.answers.some(
                (reportAnswer) =>
                  reportAnswer.question_id === answer.question_id
              )
          );
          this.report.contents.answers = this.report.contents.answers.concat(
            unfilledPreviousAnswers
          );
        }
      );
    }

    deRegisterOnExit() {
      this.killEvent(this.changeEvent);
    }

    deRegisterLifecycleEvent() {
      this.killEvent(this.lifecyclePauseEvent);
      this.killEvent(this.lifecycleResumeEvent);
    }

    registerKeyboardEvent() {
      this.keyboardService.registerStateMode(this.KEYBOARD_MODES.NATIVE);

      this.keyboardShowEvent = this.keyboardService.onKeyboardShowListener(() =>
        this.$document[0].activeElement.scrollIntoViewIfNeeded(true)
      );
    }

    deRegisterKeyboardEvent() {
      this.keyboardService.deRegisterStateMode();
      this.killEvent(this.keyboardShowEvent);
    }

    cleanFormView() {
      this.removeSectionStorage();
      this.deRegisterOnExit();
      this.deRegisterLifecycleEvent();
      this.deRegisterKeyboardEvent();
      if (this.answerChangedListener) {
        this.answerChangedListener();
      }
      if (this.reportTasksChangeListener) {
        this.reportTasksChangeListener();
      }
    }

    changeStep(direction) {
      const numeratorIndex = direction === 'next' ? 1 : -1;
      const newStep = this.currentIndex + numeratorIndex;

      this.formPristine = true;

      if (!this.isValidStep(newStep)) {
        return this.$q.when(false);
      }
      return this.setStep(newStep);
    }

    isValidStep(index) {
      return index >= 0 && index < this.formSections.length;
    }

    setStep(index) {
      return this.setNavState(index).then(() => {
        if (this.isReportDraft()) {
          this.throttle(() => this.saveLocally());
        }

        if (this.formScroll) {
          this.formScroll.scrollTop();
        }
      });
    }

    setNavState(sectionIndex) {
      if (!sectionIndex && sectionIndex !== 0) {
        return this.$q.when();
      }
      return this.$timeout(() => {
        this.currentIndex = sectionIndex;
        this.currentSection = this.formTree[this.currentIndex];
        this.nbErrors = this.getNbErrors();
        return true;
      }, 0);
    }

    /**
     * Get a HTML element Y offset relative to the top of the document.
     * @param {*} element The HTML element
     */
    getElementOffsetTop(element) {
      // The bounding rectangle of the element, relative to the viewport
      const boundingClientRect = element.getBoundingClientRect();
      // The current scroll values regarding Ionic content
      const ionicScroll = this.formScroll.getScrollPosition();

      return (
        boundingClientRect.top +
        ionicScroll.top -
        SECTION_NAVIGATION_BAR_HEIGHT -
        HEADER_BAR_HEIGHT
      );
    }

    focusQuestion(questionId) {
      const page = this.formService.getSectionIndexByQuestion(
        this.form,
        questionId
      );

      return this.setNavState(page)
        .then(() => this.$timeout(null, 0))
        .then(() => {
          const questionElem = this.$element[0].querySelector(
            `#form_question_${questionId}`
          );

          if (!questionElem) {
            return false;
          }

          const offsetTop = this.getElementOffsetTop(questionElem);

          return this.formScroll.scrollTo(0, offsetTop, true);
        });
    }

    openNavigation() {
      let modal;
      const template = `
        <sf-report-navigation-modal
          title="{{ $ctrl.title }}"
          place="$ctrl.place"
          form="$ctrl.form"
          report="$ctrl.report"
          profile="$ctrl.profile"
          sections="$ctrl.sections"
          sections-error="$ctrl.sectionsError"
          on-item-click="$ctrl.onItemClick(index)"
          on-close="$ctrl.onClose()"
          on-form-finalize="$ctrl.finalizeForm()">
        </sf-report-navigation-modal>
      `;
      const bindings = {
        title: this.form.contents.title,
        place: this.place,
        form: this.form,
        report: this.report,
        profile: this.user,
        sections: this.formSections,
        sectionsError: this.getSectionsHashError(),
        onItemClick: (index) => {
          this.setStep(index);
          return true;
        },
        finalizeForm: () => modal.remove().then(() => this.finalizeForm()),
      };

      modal = this.modalService.open(template, bindings);

      return modal;
    }

    finalizeForm() {
      this.debugService.log('Trying to send a report', {
        reportId: this.report.id,
        report: JSON.stringify(this.report),
        place: JSON.stringify(this.place),
      });

      if (!this.backupNodes && this.report.contents.nodes.length) {
        // When we on the send form screen, all the empty nodes get deleted. On mobile this means
        // that even if we return back and fill the empty answers (without restarting campaign)
        // those answers would be lost. We use nodes backup to fix that
        this.backupNodes = clone(this.report.contents.nodes);
      }

      const showInvalidAnswers = (invalidAnswersNb) => {
        this.invalidAnswersNb = invalidAnswersNb;
        this.invalidAnswersIndex = 0;
        this.invalidQuestions = this.formErrors.getQuestionsWithError();

        return this.goToError(this.invalidAnswersIndex);
      };

      const invalidAnswersNb = this.formErrors.getNbReportErrors();

      if (this.formErrors.getNbReportErrors()) {
        this.showFormError(() => showInvalidAnswers(invalidAnswersNb));
        return false;
      }

      const promise = this.isStatusConfigurable(this.form)
        ? this.selectStatusModal()
        : this.$q.when();

      return promise.then(() => {
        this.openEndingModal();
      });
    }

    showFormError(onCancel) {
      return this.popupService.showError(
        {
          title: this.$translate.instant('FORM_POPUP_SEND_FORM_ERROR_TITLE'),
          desc: this.useNewCampaigns
            ? this.$translate.instant(
                'FORM_POPUP_SEND_FORM_ERROR_DESC_CHECKLISTS'
              )
            : this.$translate.instant('FORM_POPUP_SEND_FORM_ERROR_DESC'),
          btnText: this.$translate.instant('FORM_POPUP_SEND_FORM_ERROR_BUTTON'),
        },
        onCancel
      );
    }

    getNbErrors() {
      return this.formErrors.nbRequestedQuestionsWithSubsections(
        this.currentSection
      );
    }

    goToError(invalidQuestionIndex) {
      this.formErrors.forceErrorsDisplay = true;

      const question = (this.invalidQuestions || [])[invalidQuestionIndex];

      if (!question) {
        return false;
      }

      this.focusQuestion(question._id);
      return true;
    }

    goToNextInvalidAnswer() {
      if (this.invalidAnswersIndex >= this.invalidAnswersNb - 1) {
        /* Note: $timeout is needed because two buttons
         * are overlapping, and the touch event triggers
         * them both by staying on banner close
         */
        return this.$timeout(() => {
          this.invalidAnswersIndex = 0;
          this.invalidAnswersNb = 0;
          this.invalidQuestions = null;
          return false;
        }, INVALID_ANSWER_BANNER_CLOSE_TIMEOUT);
      }
      this.invalidAnswersIndex += 1;

      return this.goToError(this.invalidAnswersIndex);
    }

    goToPreviousInvalidAnswer() {
      if (this.invalidAnswersIndex <= 0) {
        return false;
      }
      this.invalidAnswersIndex -= 1;

      return this.goToError(this.invalidAnswersIndex);
    }

    selectStatusModal() {
      const template = `
        <sf-status-selector
          current-status="$ctrl.currentStatus"
          status="$ctrl.status"
          on-close="$ctrl.onClose()"
          on-save="$ctrl.onSave()"
          translations="$ctrl.translations">
        </sf-status-selector>
      `;
      const bindings = {
        status: this.formsService.getAvailableStatus(this.form),
        currentStatus: this.report.contents.state,
        translations: this.form && this.form.i18n ? this.form.i18n : {}.undef,
      };
      const options = {
        animation: 'slide-in-top',
      };

      return this.modalService
        .openAsPromise(template, bindings, options)
        .then((status) => {
          this.report.contents.state = status.key;
          return this.report;
        });
    }

    save({ cleanReport, removeUnconfirmedPrefilled } = {}) {
      if (this.hasBackendDraftsFlag) {
        return this.pingService
          .ping(false)
          .then(() =>
            this.saveRemote({ cleanReport, removeUnconfirmedPrefilled })
          )
          .catch((err) => {
            if (
              err.status === this.SF_ERROR_CODES.CODES_SERVER_NO_NETWORK[0] &&
              this.platformService.isBrowser()
            ) {
              throw err;
            }

            return this.saveLocally({
              cleanReport,
              removeUnconfirmedPrefilled,
            });
          });
      } else {
        return this.saveLocally({ cleanReport, removeUnconfirmedPrefilled });
      }
    }

    saveLocally({ cleanReport, removeUnconfirmedPrefilled } = {}) {
      if (this.platformService.isBrowser()) {
        return false;
      }

      if (this.campaign && this.campaign.contents.version) {
        this.report.contents.campaign_version = this.campaign.contents.version;
      }

      const ctx = {
        report: this.report,
        place: this.place,
        user: this.user,
        campaign: this.campaign,
        unconfirmedPrefilledQuestionsIds:
          this.unconfirmedPrefilledQuestions.ids,
      };

      const promise = this.getSavePromise().then(() => {
        return this.formService.saveBackup(
          ctx,
          this.form,
          cleanReport,
          removeUnconfirmedPrefilled
        );
      });

      return promise
        .then((report) => {
          this.report = report;
          return report;
        })
        .finally(() => {
          this.savePromise = null;
        });
    }

    saveRemote({ cleanReport, removeUnconfirmedPrefilled } = {}) {
      if (this.campaign && this.campaign.contents.version) {
        this.report.contents.campaign_version = this.campaign.contents.version;
      }

      const ctx = {
        report: this.report,
        place: this.place,
        user: this.user,
        campaign: this.campaign,
        unconfirmedPrefilledQuestionsIds:
          this.unconfirmedPrefilledQuestions.ids,
      };

      const promise = this.getSavePromise().then(() => {
        return this.formService.saveDraft(
          ctx,
          this.form,
          cleanReport,
          removeUnconfirmedPrefilled
        );
      });

      return promise
        .then((report) => {
          this.report = report;
          return report;
        })
        .finally(() => {
          this.savePromise = null;
        });
    }

    getSavePromise() {
      return this.savePromise || this.$q.when();
    }

    removeReportTasks() {
      return this.tasksService.deleteReportTasks(this.report._id);
    }

    initTasks() {
      return this.formTasksService.init(
        this.form,
        this.report,
        this.user,
        this.place
      );
    }

    openEndingModal() {
      if (this.backupNodes && this.backupNodes.length) {
        this.report.contents.nodes = clone(this.backupNodes);
      }

      const template = `
        <sf-form-ending
          report="$ctrl.report"
          form="$ctrl.form"
          campaign="$ctrl.campaign"
          template="$ctrl.template"
          place="$ctrl.place"
          user="$ctrl.user"
          profile="$ctrl.user"
          on-saved="$ctrl.onSaved()"
          on-close="$ctrl.onModalClose(value)"
          referer="$ctrl.referer"
          is-preview="$ctrl.isPreview"
          >
        </sf-form-ending>
      `;

      const saveAsDraft = () =>
        this.saveLocally({
          cleanReport: false,
          removeUnconfirmedPrefilled: true,
        });

      const bindings = {
        campaign: this.campaign,
        report: this.report,
        template: this.template,
        form: this.form,
        place: this.place,
        profile: this.profile,
        user: this.user,
        referer: this.referer,
        isPreview: this.isPreview,
        onSaved: () => {
          bindings.onClose();
          this.cleanFormView();
          return true;
        },
        onModalClose: (shouldSaveAsDraft = false) => {
          if (shouldSaveAsDraft) {
            saveAsDraft();
          }

          bindings.onClose();
          this.registerOnExit();
          this.registerLifecycleEvent();
          return true;
        },
      };

      saveAsDraft();
      this.keyboardService.hide();

      this.deRegisterOnExit();
      this.deRegisterLifecycleEvent();

      return this.modalService.open(template, bindings);
    }

    openActionSheetOnExit(evt) {
      if (this.$stateParams.online) {
        this.removeReportTasks();
      }

      if (!this.isFormDirty()) {
        return true;
      }

      evt.preventDefault();

      const promise = this.isReportDraft()
        ? this.exitAction().then((data) => {
            if (data !== 'exit_without_save') {
              this.updateOtherViews();
            }
            return data;
          })
        : this.formSheetService.openCancel();

      return promise
        .then(() => {
          return this.onSuccessDraftSave();
        })
        .finally(() => {
          this.$urlRouter.sync();
        });
    }

    onSuccessDraftSave() {
      this.deRegisterOnExit();
      return this.routerService.goBack();
    }

    openPlaceInformation(place) {
      const template = `
        <ion-modal-view>
          <sf-view class="sf_view__has_tabs">
            <sf-nav-bar
              title="{{ $ctrl.place.contents.name }}">
              <div class="buttons buttons-left">
                <sf-button-header-close
                  ng-click="$ctrl.onClose()">
                </sf-button-header-close>
              </div>
            </sf-nav-bar>

            <sf-content
              class="sf_view__content"
              current-sticky-handle="$ctrl.currentStickyHandle"
              has-multiple-sticky="true">
              <sf-content-header>
                <h2>{{ $ctrl.place.contents.name }}</h2>
              </sf-content-header>

              <ion-tabs class="tabs-top tabs-striped tabs-light tabs-fixed">
                <!-- INFORMATIONS -->
                <ion-tab title="{{ 'PLACE_INFOS_INFOS_TAB' | translate }}"
                  on-select="$ctrl.currentStickyHandle = 'placeInfos'">
                  <sf-content
                    class="sf_view__content sticky_screen_top"
                    key="placeInfosContent"
                    force-js-scroll="true"
                  >
                    <sf-place-params place="$ctrl.place"></sf-place-params>
                  </sf-content>
                </ion-tab>

                <!-- DOCUMENTS -->
                <ion-tab title="{{ 'PLACE_INFOS_DOCS_TAB' | translate }}"
                  on-select="$ctrl.currentStickyHandle = 'placeDocuments'
                    && $ctrl.reloadDocuments()"
                  >
                  <sf-place-documents
                    place="$ctrl.place"
                    reload-fn="$ctrl.reloadDocuments"
                    is-navbar-hidden="true"
                  >
                  </sf-place-documents
                </ion-tab>
              </ion-tabs>
            </sf-content>
          </sf-view>
        </ion-modal-view>
      `;
      const bindings = { place };

      return this.modalService.open(template, bindings);
    }

    exitAction() {
      return this.formSheetService
        .openExit(this.useNewCampaigns)
        .then((action) => {
          switch (action) {
            case 'delete':
              return this.formService.deleteBackup(this.report._id);
            case 'cancel_delete':
            case 'back_to_reports':
              return this.$q.resolve('exit_without_save');
            default:
              return this.saveWithModal();
          }
        });
    }

    saveWithModal() {
      const sendPromise = this.$q.defer();
      const popupRequest = this.popupRequestService.show(
        this.FORM_ENDING_POPUP_SAVE_CONFIG,
        () => sendPromise.resolve()
      );

      this.awakeFactory.keepAwake();

      return this.save({
        cleanReport: true,
        removeUnconfirmedPrefilled: true,
      })
        .then(() => popupRequest.onSuccess())
        .catch((err) => {
          const messageError = this.errorMessagesService.getMessage(err, {
            customUnknownErrorMessage: 'FORM_UNKNOWN_ERROR',
          });

          if (
            this.platformService.isBrowser() &&
            err.status === this.SF_ERROR_CODES.CODES_SERVER_NO_NETWORK[0]
          ) {
            popupRequest.onError(
              () =>
                this.saveWithModal().then(() => {
                  this.updateOtherViews();
                  this.onSuccessDraftSave();
                }),
              () => this.$q.resolve(),
              messageError,
              false
            );

            throw err;
          }
        })
        .finally(() => {
          this.awakeFactory.allowSleepAgain();
        });
    }

    updateOtherViews() {
      this.pubSubService.publish('FORM_REPORT_UPDATED');

      return true;
    }

    removeSectionStorage() {
      this.localStorageService.remove(FORM_CURRENT_SECTION_STORAGE_KEY);
    }

    displaySectionError(sectionId) {
      return (
        this.formErrors.forceErrorsDisplay &&
        this.formErrors.nbRequestedQuestions(sectionId)
      );
    }

    getSectionsHashError() {
      return this.formSections.reduce((output, section) => {
        output[section._id] = this.displaySectionError(section._id);
        return output;
      }, {});
    }

    isReportDraft() {
      return (
        this.report.localStatus === 'draft' ||
        this.report.contents.state === 'draft'
      );
    }

    isStatusConfigurable(currentForm) {
      if (this.campaign || this.template) {
        return (
          currentForm.contents.statusMap &&
          NB_STATUS_CAMPAIGN_MIN < currentForm.contents.statusMap.length
        );
      }

      return (
        currentForm.contents.statusMap &&
        NB_STATUS_MIN < currentForm.contents.statusMap.length
      );
    }

    isFormDirty() {
      return (
        this.formPristine || !!(this.questionForm && this.questionForm.$dirty)
      );
    }

    throttle(func) {
      if (!this.timerSaved) {
        func();
        this.timerSaved = this.$timeout(() => {}, WAITING_SAVE_TIME);
      } else if (!this.waitingSaved) {
        this.waitingSaved = true;
        this.timerSaved.then(() => {
          this.waitingSaved = false;
          this.timerSaved = null;
          func();
        });
      }
    }

    hasPrefillAnswers(form) {
      return form.contents.questions.some((q) => q.prefillWithLastAnswer);
    }

    buildNodesForPreviousAnswers(lastAnswers) {
      // Note: This is needed to associate a node id to an answer from a previous report
      return this.campaign
        ? this.campaignsUtilsService.buildNodesForPreviousAnswers(
            this.campaign,
            this.report,
            lastAnswers
          )
        : this.formService.buildNodesForPreviousAnswers(
            this.form,
            this.report,
            lastAnswers
          );
    }

    calendarEventCreated(questionId, event) {
      const newItem = { questionId, event };
      this.events = [newItem, ...this.events];
    }

    calendarEventDeleted(eventId) {
      const eventItem = this.events.find(({ event }) => event._id === eventId);
      if (eventItem) {
        const eventIndex = this.events.indexOf(eventItem);
        this.events.splice(eventIndex, 1);
        this.events = [...this.events];
      }
    }

    openModalPOSAuditParam() {
      this.campaignsUtilsService
        .openParamSelectorModal(
          this.campaign,
          this.place._id,
          this.referer,
          this.$stateParams
        )
        .then((selectedPosAuditId) => {
          if (selectedPosAuditId) {
            this.placesParamsService
              .getParamById(this.campaign.contents.posAuditParam_id)
              .then((posAuditParam) => {
                const param = posAuditParam.values.find(
                  ({ id }) => id === selectedPosAuditId
                );

                const posAudit = {
                  param_id: this.campaign.contents.posAuditParam_id,
                  paramLabel: posAuditParam.label,
                  value: param.value,
                  value_id: selectedPosAuditId,
                };

                this.report.contents.posAudit = posAudit;
              });
          }
        });
    }
  },
};
